<template>
  <v-app>
    <v-card class="overflow-hidden rounded-0">
      <v-app-bar class="greenNav" dense elevation="1" flat>
        <v-toolbar-title>Centerpartiet</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
    </v-card>

    <v-container v-if="hasSent == true">
      <v-card>
        <v-alert type="success" dismissible>
          Hej!<br>
          Ditt formulär med ansökan för ersättning har skickats.<br>
          En sammanställning över din ansökan skickas per e-post, om du har angett en e-postadress.
        </v-alert>
      </v-card>
    </v-container>


    <v-container style="display:flex;justify-content: center;">
      <v-col cols="12" sm="12" md="12" lg="8" xl="6">
        <v-card
          style="height:auto; margin-top:50px; /*background: linear-gradient(148deg, rgba(255,255,255,1) 89%, rgba(255,255,255,1) 89%, rgba(17,72,56,1) 89%, rgba(17,72,56,1) 97%);*/">
          <v-col col="12" class="d-flex mb-5 justify-center cardHeader">
            <img src="centerBild.png" width="75px" height="75px"
              style="background-color:#fff; border-radius: 50%; padding:10px;">
          </v-col>
          <v-col v-if="hasSent == true" class="text-center">
            <v-btn color="primary" class="mr-4 mt-1" @click="addNewForm">Nytt formulär</v-btn>
          </v-col>
          <form @submit.prevent="submit" style="padding-left:20px;padding-right:20px;" v-if="hasSent != true">

            <v-text-field v-model="activityRequest" @focus="changeActive('activity')" label="Aktivitet" type="text"
              @blur="$v.activityRequest.$touch()"></v-text-field>
            <v-alert type="error" v-if="!$v.activityRequest.required && $v.activityRequest.$dirty">Vänligen ange en
              aktivitet</v-alert>

            <v-select :items="organisation_options" item-text="options" @blur="$v.organisation.$touch()" item-value="val" v-model="organisation" label="Organisation" @focus="changeActive('organisation')"></v-select>
            <v-alert type="error" v-if="!$v.organisation.required && $v.organisation.$dirty">Vänligen ange din organisation</v-alert>

            <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="costLocation" @focus="changeActive('costLocation')"
                label="Kostnadsställe" type="text"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="costProject" @focus="changeActive('costProject')"
                label="Projekt" type="text"></v-text-field>
            </v-col>
          </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="firstName" @blur="$v.firstName.$touch()" @focus="changeActive('firstName')"
                  label="Förnamn" type="text"></v-text-field>
                <v-alert type="error" v-if="!$v.firstName.required && $v.firstName.$dirty">Vänligen fyll i ditt
                  förnamn</v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="lastName" @blur="$v.lastName.$touch()" @focus="changeActive('lastName')"
                  label="Efternamn" type="text"></v-text-field>
                <v-alert type="error" v-if="!$v.lastName.required && $v.lastName.$dirty">Vänligen fyll i ditt
                  efternamn</v-alert>
              </v-col>
            </v-row>


            <label class=text--secondary>Personnummer</label>
            <div class="d-flex flex-column flex-md-row" max-width="200">
              <div class="pr-8"> <v-otp-input v-model="ssnYear" @input="yearCheck" length="4" placeholder="Y"
                  @focus="changeActive('ssn')" ref="YY" @blur="$v.ssnYear.$touch()"></v-otp-input> </div>
              <div class="pr-8"> <v-otp-input v-model="ssnMM" @input="monthCheck" length="2" placeholder="mm" ref="MM"
                  @focus="changeActive('ssn')" @blur="$v.ssnMM.$touch()"></v-otp-input> </div>
              <div class="pr-8  "> <v-otp-input v-model="ssnDD" @input="dateCheck" length="2" placeholder="dd" ref="DD"
                  @focus="changeActive('ssn')" @blur="$v.ssnDD.$touch()"></v-otp-input></div>
              <div class="pr-8 pt-0 centerCenter d-none d-md-flex">—</div>
              <div class="pr-8"> <v-otp-input v-model="ssnXX" length="4" ref="XX" @input="xxCheck" placeholder="X"
                  @blur="$v.ssnXX.$touch()" @focus="changeActive('ssn')"> </v-otp-input> </div>
            </div>
            <v-alert type="error"
              v-if="($v.ssnYear.$dirty || $v.ssnMM.$dirty || $v.ssnDD.$dirty || $v.ssnXX.$dirty) && !$v.ssn.isValidSSN && activeWindow !== 'ssn'">Vänligen
              fyll i ett fullständigt personnummer</v-alert>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="homeAddress" label="Gatuadress/Utdelningsadress" @blur="$v.homeAddress.$touch()"
                  @focus="changeActive('homeAdress')" type="text"></v-text-field>
                <v-alert type="error" v-if="!$v.homeAddress.required && $v.homeAddress.$dirty">Vänligen ange din
                  adress</v-alert>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="zipCode" label="Postnummer" @blur="$v.zipCode.$touch()"
                  @focus="changeActive('zipCode')" type="numbers"></v-text-field>
                <v-alert type="error" v-if="!$v.zipCode.required && $v.zipCode.$dirty">Vänligen ange ditt
                  postnummer</v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="region" label="Ort" @blur="$v.region.$touch()" @focus="changeActive('region')"
                  type="text"></v-text-field>
                <v-alert type="error" v-if="!$v.region.required && $v.region.$dirty">Vänligen ange din ort</v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="userPhone" label="Telefonnummer" @blur="$v.userPhone.$touch()" type="tel"
                  @focus="changeActive('userPhone')"></v-text-field>
                <v-alert type="error" v-if="!$v.userPhone.required && $v.userPhone.$dirty">Vänligen ange ditt
                  telefonnummer</v-alert>
                <v-alert type="error" v-if="!$v.userPhone.checkPhone && $v.userPhone.$dirty">Felaktigt telefonnummer
                  angivet. Vänligen ange ditt telefonnummer med +46700000000 eller 0700000000, utan mellanrum.</v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field v-model="userEmail" label="Epostadress" @blur="$v.userEmail.$touch()"
                  @focus="changeActive('userEmail')" type="email"></v-text-field>
                <v-alert type="error" v-if="!$v.userEmail.required && $v.userEmail.$dirty">Vänligen ange din
                  epost</v-alert>
                <v-alert type="error" v-if="!$v.userEmail.email && $v.userEmail.$dirty">Felaktig epost angiven</v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="bankClearing" label="Clearingnummer" @blur="$v.bankClearing.$touch()" type="text"
                  @focus="changeActive('bankClearing')"></v-text-field>
                <v-alert type="error" v-if="!$v.bankClearing.required && $v.bankClearing.$dirty">Vänligen ange ditt
                  clearingnummer</v-alert>
                <v-alert type="error"
                  v-if="$v.bankClearing.required && $v.bankClearing.$dirty && !$v.bankClearing.validateClearing">Vänligen
                  ange ett fullständigt clearingnummer</v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="bankAccountNumber" label="Kontonummer" @blur="$v.bankAccountNumber.$touch()"
                  type="text" @focus="changeActive('accountNumber')"></v-text-field>
                <v-alert type="error" v-if="!$v.bankAccountNumber.required && $v.bankAccountNumber.$dirty">Vänligen ange
                  ditt kontonummer</v-alert>
              </v-col>
            </v-row>
            <v-text-field v-model="userBank" label="Bank" @blur="$v.userBank.$touch()" type="text"
              @focus="changeActive('userBank')"></v-text-field>
            <v-alert type="error" v-if="!$v.userBank.required && $v.userBank.$dirty">Vänligen ange din banks
              namn</v-alert>




            <h3 class="pb-3">Yrkar ersättning för</h3>
            <h4>Milersättning</h4>
            <div v-if="(milesInput.length > 0)">
              <div v-for="(miles, milesIndex) in milesInput" :key="milesIndex" class="formBox">
                Milersättning {{ milesIndex + 1 }}
                <v-row>
                  <v-col cols="12" md=6>
                    <v-text-field v-model="miles.milesFrom" label="Från ort"
                      @blur="$v.milesInput.$each[milesIndex].milesFrom.$touch()" @focus="changeActive('milesFrom')"
                      type="text"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.milesInput.$each[milesIndex].milesFrom.required && $v.milesInput.$each[milesIndex].milesFrom.$dirty">Fyll
                      i "Från ort"</v-alert>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="miles.milesTo" label="Till ort" type="text"
                      @blur="milesValidation(milesIndex, 'milesTo').$touch()"
                      @focus="changeActive('milesto')"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.milesInput.$each[milesIndex].milesTo.required && $v.milesInput.$each[milesIndex].milesTo.$dirty">Fyll
                      i "Till ort"</v-alert>
                  </v-col>
                </v-row>


                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="miles.milesDateFrom" label="Startdatum" type="date" max="2999-12-31"
                      @blur="milesValidation(milesIndex, 'milesDateFrom').$touch()"
                      @focus="changeActive('milesDatefrom')"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.milesInput.$each[milesIndex].milesDateFrom.required && $v.milesInput.$each[milesIndex].milesDateFrom.$dirty">Fyll
                      i startdatum</v-alert>
                    <v-alert type="error"
                      v-if="$v.milesInput.$each[milesIndex].milesDateFrom.required && !$v.milesInput.$each[milesIndex].milesDateFrom.checkDateFormat && $v.milesInput.$each[milesIndex].milesDateFrom.$dirty">Fel
                      format på datum</v-alert>
                    <v-alert type="error"
                      v-if="$v.milesInput.$each[milesIndex].milesDateFrom.required && $v.milesInput.$each[milesIndex].milesDateFrom.checkDateFormat && !$v.milesInput.$each[milesIndex].milesDateFrom.checkDatePrev && $v.milesInput.$each[milesIndex].milesDateTo.$dirty">Datumet
                      behöver ha passerat</v-alert>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="miles.milesDateTo" label="Slutdatum" type="date" max="2999-12-31"
                      @blur="milesValidation(milesIndex, 'milesDateTo').$touch()"
                      @focus="changeActive('milesDateTo')"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.milesInput.$each[milesIndex].milesDateTo.required && $v.milesInput.$each[milesIndex].milesDateTo.$dirty">Fyll
                      i slutdatum</v-alert>
                    <v-alert type="error"
                      v-if="$v.milesInput.$each[milesIndex].milesDateTo.required && !$v.milesInput.$each[milesIndex].milesDateTo.checkDateFormat && $v.milesInput.$each[milesIndex].milesDateTo.$dirty">Fel
                      format på datum</v-alert>
                    <v-alert type="error"
                      v-if="$v.milesInput.$each[milesIndex].milesDateTo.required && $v.milesInput.$each[milesIndex].milesDateTo.checkDateFormat && !$v.milesInput.$each[milesIndex].milesDateTo.checkDatePrev && $v.milesInput.$each[milesIndex].milesDateTo.$dirty">Datumet
                      behöver ha passerat</v-alert>
                  </v-col>
                </v-row>

                <v-text-field v-model="miles.drovenMiles" label="Antal körda km" type="number"
                  @blur="milesValidation(milesIndex, 'drovenMiles').$touch()"
                  @focus="changeActive('drovenMiles')"></v-text-field>
                <v-alert type="error"
                  v-if="!$v.milesInput.$each[milesIndex].drovenMiles.required && $v.milesInput.$each[milesIndex].drovenMiles.$dirty">Fyll
                  i totalt körda kilometer</v-alert>
                <v-alert type="error"
                  v-if="!$v.milesInput.$each[milesIndex].drovenMiles.numeric && $v.milesInput.$each[milesIndex].drovenMiles.$dirty">Fyll
                  endast i siffror</v-alert>

                <v-btn class="mr-4 mb-1 mt-1" color="error" @click="removeMilesInput(milesIndex)">Ta bort<v-icon dark
                    right>mdi-minus-circle</v-icon></v-btn>

              </div>
            </div>
            <v-btn color="primary" class="mr-4 mt-1" @click="addMilesInput">Lägg till milersättning</v-btn>


            <h4 class="pt-5">Utlägg</h4>
            <div v-if="(expensesInput.length > 0)">
              <div v-for="(expenses, expensesIndex) in expensesInput" :key="expensesIndex" class="formBox">
                Utlägg {{ expensesIndex + 1 }}

                <v-text-field v-model="expenses.accountableExpense"
                  @blur="$v.expensesInput.$each[expensesIndex].accountableExpense.$touch()"
                  @focus="changeActive('accountableExpense')" type="text"
                  label="Möjlighet att lämna egen kommentar"></v-text-field>
                <v-alert
                  v-if="!$v.expensesInput.$each[expensesIndex].accountableExpense.required && $v.expensesInput.$each[expensesIndex].accountableExpense.$dirty"
                  type="error">Vänligen lämna en kommentar för beloppet</v-alert>
                <v-select :items="expensesData" item-text="expensesDataText" item-value="expensesDataValue"
                  label="Utläggstyp" v-model="expenses.expenseType"
                  @blur="$v.expensesInput.$each[expensesIndex].expenseType.$touch()"></v-select>
                <v-alert type="error"
                  v-if="!$v.expensesInput.$each[expensesIndex].expenseType.validateExpenseType && !$v.expensesInput.$each[expensesIndex].expenseType.required && $v.expensesInput.$each[expensesIndex].expenseType.$dirty">Välj
                  ett korrekt alternativ</v-alert>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="expenses.accountableAmount"
                      @blur="$v.expensesInput.$each[expensesIndex].accountableAmount.$touch()" type="text" label="Belopp"
                      @focus="changeActive('accountableAmount')"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.expensesInput.$each[expensesIndex].accountableAmount.required && $v.expensesInput.$each[expensesIndex].accountableAmount.$dirty">Fyll
                      i totalbeloppet</v-alert>
                    <v-alert type="error"
                      v-if="$v.expensesInput.$each[expensesIndex].accountableAmount.required && !$v.expensesInput.$each[expensesIndex].accountableAmount.decimal && $v.expensesInput.$each[expensesIndex].accountableAmount.$dirty">Fyll
                      i beloppet med siffror och decimaler. Använd punkt som decimal.</v-alert>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="expenses.expenseDate"
                      @blur="$v.expensesInput.$each[expensesIndex].expenseDate.$touch()" label="Datum" type="date"
                      max="2999-12-31" @focus="changeActive('expenseDate')"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.expensesInput.$each[expensesIndex].expenseDate.required && $v.expensesInput.$each[expensesIndex].expenseDate.$dirty">Fyll
                      i datum</v-alert>
                    <v-alert type="error"
                      v-if="$v.expensesInput.$each[expensesIndex].expenseDate.required && !$v.expensesInput.$each[expensesIndex].expenseDate.checkDateFormat && $v.expensesInput.$each[expensesIndex].expenseDate.$dirty">Fel
                      format på datum</v-alert>
                    <v-alert type="error"
                      v-if="$v.expensesInput.$each[expensesIndex].expenseDate.required && $v.expensesInput.$each[expensesIndex].expenseDate.checkDateFormat && !$v.expensesInput.$each[expensesIndex].expenseDate.checkDatePrev && $v.expensesInput.$each[expensesIndex].expenseDate.$dirty">Datumet
                      behöver ha passerat</v-alert>
                  </v-col>
                </v-row>
                <v-file-input @change="uploadFile($event, expensesIndex)" type="file" label="Bifoga kvitto/Ladda upp fil"
                  @focus="changeActive('uploadFile')" hint="Accepterade filformat är pdf, doc, docx, png, jpg samt jpeg."
                  persistent-hint></v-file-input>
                <v-alert type="error"
                  v-if="!expensesInput[expensesIndex].format && expensesInput[expensesIndex].touched">Felaktigt format,
                  vänligen ladda upp som pdf, png, jpg eller jpeg.</v-alert>
                <!-- <v-alert type="error" v-if="!expensesInput[expensesIndex].size && expensesInput[expensesIndex].touched">Filen är för stor. Vänligen välj en fil under 30mb</v-alert> -->
                <v-alert type="error"
                  v-if="!expensesInput[expensesIndex].proofDocIsOkay && expensesInput[expensesIndex].touched">Vänligen
                  ange ett giltigt dokument</v-alert>

                <v-btn color="error" class="mr-4 mb-1 mt-1" @click="removeExpense(expensesIndex)">Ta bort<v-icon dark
                    right>mdi-minus-circle</v-icon></v-btn>
              </div>
            </div>
            <v-btn color="primary" class="mr-4 mt-1" @click="addExpense">Lägg till utlägg</v-btn>


            <h4 class="pt-5">Traktamente</h4>
            <div v-if="(reimbrushmentInput.length != 0)">
              <div v-for="(reimbrushment, reimbrushmentIndex) in reimbrushmentInput" :key="reimbrushmentIndex"
                class="formBox">
                Traktamente {{ reimbrushmentIndex + 1 }}

                <v-text-field v-model="reimbrushment.description"
                  @blur="$v.reimbrushmentInput.$each[reimbrushmentIndex].description.$touch()" type="text"
                  @focus="changeActive('description')" label="Beskrivning"></v-text-field>
                <v-alert type="error"
                  v-if="!$v.reimbrushmentInput.$each[reimbrushmentIndex].description.required && $v.reimbrushmentInput.$each[reimbrushmentIndex].description.$dirty">Ange
                  en beskrivning</v-alert>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="reimbrushment.dateFrom"
                      @blur="$v.reimbrushmentInput.$each[reimbrushmentIndex].dateFrom.$touch()" label="Datum från"
                      type="date" max="2999-12-31" @focus="changeActive('dateFrom')"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.reimbrushmentInput.$each[reimbrushmentIndex].dateFrom.required && $v.reimbrushmentInput.$each[reimbrushmentIndex].dateFrom.$dirty">Ange
                      ett giltigt datum</v-alert>
                    <v-alert type="error"
                      v-if="$v.reimbrushmentInput.$each[reimbrushmentIndex].dateFrom.required && !$v.reimbrushmentInput.$each[reimbrushmentIndex].dateFrom.checkDateFormat && $v.reimbrushmentInput.$each[reimbrushmentIndex].dateFrom.$dirty">Fel
                      format på datum</v-alert>
                    <v-alert type="error"
                      v-if="$v.reimbrushmentInput.$each[reimbrushmentIndex].dateFrom.required && $v.reimbrushmentInput.$each[reimbrushmentIndex].dateFrom.checkDateFormat && !$v.reimbrushmentInput.$each[reimbrushmentIndex].dateFrom.checkDatePrev && $v.reimbrushmentInput.$each[reimbrushmentIndex].dateFrom.$dirty">Datumet
                      behöver ha passerat</v-alert>
                  </v-col>
                  <v-col cols="12" md=6>
                    <v-text-field v-model="reimbrushment.dateTo"
                      @blur="$v.reimbrushmentInput.$each[reimbrushmentIndex].dateTo.$touch()" label="Datum till"
                      type="date" max="2999-12-31" @focus="changeActive('dateTo')"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.reimbrushmentInput.$each[reimbrushmentIndex].dateTo.required && $v.reimbrushmentInput.$each[reimbrushmentIndex].dateTo.$dirty">Ange
                      ett giltigt datum</v-alert>
                    <v-alert type="error"
                      v-if="$v.reimbrushmentInput.$each[reimbrushmentIndex].dateTo.required && !$v.reimbrushmentInput.$each[reimbrushmentIndex].dateTo.checkDateFormat && $v.reimbrushmentInput.$each[reimbrushmentIndex].dateTo.$dirty">Fel
                      format på datum</v-alert>
                    <v-alert type="error"
                      v-if="$v.reimbrushmentInput.$each[reimbrushmentIndex].dateTo.required && $v.reimbrushmentInput.$each[reimbrushmentIndex].dateTo.checkDateFormat && !$v.reimbrushmentInput.$each[reimbrushmentIndex].dateTo.checkDatePrev && $v.reimbrushmentInput.$each[reimbrushmentIndex].dateTo.$dirty">Datumet
                      behöver ha passerat</v-alert>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="reimbrushment.totalNights"
                      @blur="$v.reimbrushmentInput.$each[reimbrushmentIndex].totalNights.$touch()"
                      label="Antal Övernattningar" @focus="changeActive('totalNights')" type="number"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.reimbrushmentInput.$each[reimbrushmentIndex].totalNights.required && $v.reimbrushmentInput.$each[reimbrushmentIndex].totalNights.$dirty">Ange
                      antal nätter, minst 0</v-alert>
                    <v-alert type="error"
                      v-if="!$v.reimbrushmentInput.$each[reimbrushmentIndex].totalNights.numeric && $v.reimbrushmentInput.$each[reimbrushmentIndex].totalNights.$dirty">Ange
                      antal nätter i siffror</v-alert>
                  </v-col>
                  <v-col cols="12" md="6">
                  </v-col>
                </v-row>
                <h5 class="mb-3">Fria måltider</h5>
                <v-row>
                  <v-col>
                    <v-text-field v-model="reimbrushment.freeMealsBR"
                      @blur="$v.reimbrushmentInput.$each[reimbrushmentIndex].freeMealsBR.$touch()" label="Fria frukostar"
                      @focus="changeActive('freeMeals')" type="number"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.reimbrushmentInput.$each[reimbrushmentIndex].freeMealsBR.numeric && $v.reimbrushmentInput.$each[reimbrushmentIndex].freeMealsBR.$dirty">Ange
                      antal fria måltider i siffror</v-alert>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="reimbrushment.freeMealsLN"
                      @blur="$v.reimbrushmentInput.$each[reimbrushmentIndex].freeMealsLN.$touch()" label="Fria luncher"
                      @focus="changeActive('freeMealsLN')" type="number"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.reimbrushmentInput.$each[reimbrushmentIndex].freeMealsLN.numeric && $v.reimbrushmentInput.$each[reimbrushmentIndex].freeMealsLN.$dirty">Ange
                      antal fria måltider i siffror</v-alert>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="reimbrushment.freeMealsDN"
                      @blur="$v.reimbrushmentInput.$each[reimbrushmentIndex].freeMealsDN.$touch()" label="Fria middagar"
                      @focus="changeActive('freeMealsDN')" type="number"></v-text-field>
                    <v-alert type="error"
                      v-if="!$v.reimbrushmentInput.$each[reimbrushmentIndex].freeMealsDN.numeric && $v.reimbrushmentInput.$each[reimbrushmentIndex].freeMealsDN.$dirty">Ange
                      antal fria måltider i siffror</v-alert>
                  </v-col>
                </v-row>
                <v-btn color="error" class="mr-4 mb-1 mt-1" @click="removeReimbrushment(reimbrushmentIndex)">Ta
                  bort<v-icon dark right>mdi-minus-circle</v-icon></v-btn><a
                  href="https://www.skatteverket.se/privat/skatter/arbeteochinkomst/traktamente.4.dfe345a107ebcc9baf80006547.html"
                  target="_blank"><v-btn color="primary" text>Länk till regelverk</v-btn></a>
              </div>
            </div>
            <v-btn color="primary" class="mr-4 mt-1" @click="addReimbrushment">Lägg till traktamente</v-btn>

            <h4 class="pt-5">Mötesarvode</h4>
            <div v-if="(meetingInput.length > 0)">
              <div v-for="(meeting, meetingIndex) in meetingInput" :key="meetingIndex" class="formBox">
                Mötesarvode {{ meetingIndex + 1 }}
                <v-row>
                  <v-col>
                    <v-text-field v-model="meeting.date"
                      @blur="$v.meetingInput.$each[meetingIndex].date.$touch()" label="Datum"
                      type="date" max="2999-12-31" @focus="changeActive('meetingDate')"></v-text-field>
                      <v-alert type="error" v-if="!$v.meetingInput.$each[meetingIndex].date.dateUnique && $v.meetingInput.$each[meetingIndex].date.$dirty">Ange endast 1 möte per datum</v-alert>
                      <v-alert type="error" v-if="!$v.meetingInput.$each[meetingIndex].date.required && $v.meetingInput.$each[meetingIndex].date.$dirty">Vänligen ange ett datum</v-alert>
                    <v-alert type="error" v-if="$v.meetingInput.$each[meetingIndex].date.required && !$v.meetingInput.$each[meetingIndex].date.checkDateFormat && $v.meetingInput.$each[meetingIndex].date.$dirty">Fel format på datum</v-alert>
                    <v-alert type="error" v-if="$v.meetingInput.$each[meetingIndex].date.required && $v.meetingInput.$each[meetingIndex].date.checkDateFormat && !$v.meetingInput.$each[meetingIndex].date.checkDatePrev && $v.meetingInput.$each[meetingIndex].date.$dirty">Datumet behöver ha passerat</v-alert>
                  </v-col>
                  <v-col>
                    <v-select v-model="meeting.type" :items="meeting_options" label="Längd på möte" @blur="$v.meetingInput.$each[meetingIndex].type.$touch()" item-text="options" item-value="values"></v-select>
                    <v-alert type="error" v-if="!$v.meetingInput.$each[meetingIndex].type.required && $v.meetingInput.$each[meetingIndex].type.$dirty">Vänligen välj ett val</v-alert>
                  </v-col>
                </v-row>
                <v-row>
                <v-col>
                    <v-text-field v-model="meeting.comment"
                      label="Frivillig kommentar"
                      @focus="changeActive('meetingComment')" type="text"></v-text-field>
                  </v-col>
                  </v-row>

                <v-btn color="error" class="mr-4 mb-1 mt-1" @click="removeMeeting(meetingIndex)">
                  Ta bort
                  <v-icon dark
                    right>mdi-minus-circle
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <v-btn color="primary" class="mr-4 mt-1 mb-4" @click="addMeeting">Lägg till mötesarvode</v-btn>


            <v-text-field v-model="userManager" label="Attesterande chef/eller annan, ange namn"></v-text-field>
            <v-btn color="success" class="mr-4 mb-2" @focus="changeActive('accountableExpense')" @click="submit">Skicka
              in</v-btn>
            <v-progress-circular v-if="waitingResponse" :size="18" :width="5" color="primary"
              indeterminate></v-progress-circular>
            <div>
            </div>
          </form>
          <v-alert v-if="sentError" type="error">Nätverksfel. Vänligen försök igen.</v-alert>
          <v-alert class="mt-3 mb-0" type="error" v-if="hasError && $v.$invalid">
            Vänligen kontrollera samtliga fält
          </v-alert>
        </v-card>
      </v-col>
    </v-container>
  </v-app>
</template>

<script>
import { required, email, numeric, decimal } from 'vuelidate/lib/validators';
import axios from 'axios';
// import { triggerRef } from 'vue';

function checkDateFormat(dateVal) {
  var newDate = new Date(dateVal);
  if (newDate instanceof Date && isFinite(newDate.getTime())) {
    return true;
  } else {
    return false;
  }
}

function yearLength(val) {
  if (val.length != 4) {
    return false;
  } else {
    return true;
  }
}

function ddLength(val) {
  if (val.length != 2) {
    return false;
  } else {
    return true;
  }
}

function dateUnique(dateVal){


  if(this.meetingInput) {
    const count = this.meetingInput.reduce((acc, obj) => obj['date'] === dateVal ? acc + 1 : acc, 0);
    if(count > 1) {
      return false;
    }
  }

  return true;
}

function validateExpenseType(val) {
  const options = ['717', '700', '727', '744', '730', '722'];
  return options.includes(val);
}

function isValidSSN(val) {
  if (typeof val !== 'string' || val.length !== 12) {
    return false;
  }

  const year = val.slice(0, 4);
  const month = val.slice(4, 6);
  const day = val.slice(6, 8);
  const sequenceNumber = val.slice(8);

  const yearInt = parseInt(year, 10);
  const monthInt = parseInt(month, 10);
  const dayInt = parseInt(day, 10);
  const sequenceNumberInt = parseInt(sequenceNumber, 10);

  if (isNaN(yearInt) || isNaN(monthInt) || isNaN(dayInt)) {
    return false;
  }


  const isLeapYear = (yearInt % 4 === 0 && yearInt % 100 !== 0) || (yearInt % 400 === 0);
  const maxDaysInMonth = [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (
    monthInt < 1 || monthInt > 12 ||
    dayInt < 1 || dayInt > maxDaysInMonth[monthInt - 1]
  ) {
    return false;
  }

  if (yearInt > 2099) {
    return false;
  }
  if (yearInt < 1900) {
    return false;
  }

  if (isNaN(sequenceNumberInt) || sequenceNumber.length !== 4) {
    return false;
  }

  return true;
}

function checkDatePrev(dateVal) {
  var newDate = new Date(dateVal);
  var currentDate = new Date();
  if (newDate instanceof Date && isFinite(newDate.getTime()) && newDate < currentDate) {
    return true;
  } else {
    return false;
  }
}

function validateClearing(val) {
  if (val.length < 4 || val.length > 5) {
    return false;
  } else {
    return true;
  }
}


function checkPhone(phoneVal) {
  if (phoneVal.length > 1 && typeof phoneVal !== 'undefined' && phoneVal !== null) {
    var allowedPhone = /^(\+\d*)?\d+$/;
    if (!allowedPhone.test(phoneVal)) {
      return false;
    } else {
      return true;
    }
  }
  return true
}


export default {
  name: 'App',

  data() {
    return {
      activityRequest: '',
      existing_dates: [],
      organisation: '',
      costLocation: '',
      costProject: '',
      firstName: '',
      lastName: '',
      homeAddress: '',
      zipCode: '',
      region: '',
      userEmail: '',
      userPhone: '',
      userBank: '',
      bankClearing: '',
      bankAccountNumber: '',
      milesInput: [],
      expensesInput: [],
      reimbrushmentInput: [],
      meetingInput: [],
      userManager: '',
      ssnYear: '',
      ssnMM: '',
      ssnDD: '',
      ssnXX: '',
      ssn: '',
      activeWindow: '',
      hasError: false,
      hasSent: false,
      sentError: false,
      waitingResponse: false,
      expensesData: [
        { expensesDataText: 'Hotell / Logi', expensesDataValue: '717' },
        { expensesDataText: 'Resekostnader', expensesDataValue: '700' },
        { expensesDataText: 'Tidningar', expensesDataValue: '727' },
        { expensesDataText: 'Postbefordran och frakt', expensesDataValue: '744' },
        { expensesDataText: 'Fika, övriga personalkostnader', expensesDataValue: '730' },
        { expensesDataText: 'Lokalkostnader', expensesDataValue: '722' }
      ],
      organisation_options: [
        {options: 'Centerpartiets Riksorganisation (802000-5420)', val: 'Centerpartiets Riksorganisation, 802000-5420'},
        {options: 'Centerpartiets Ungdomsförbund (802000-5438)', val: 'Centerpartiets Ungdomsförbund, 802000-5438'},
        {options: 'Centerstudenter (802403-8740)', val: 'Centerstudenter, 802403-8740'},
        {options: 'Centerpartiets Internationella stiftelse (802406-2047)', val: 'Centerpartiets Internationella stiftelse, 802406-2047'},
        {options: 'Centerkvinnorna (802001-9736)', val: 'Centerkvinnorna, 802001-9736'},
      ],
      meeting_options: [
        {options: '0-2 Timmar', values: '90'},
        {options: '3-5 Timmar', values: '91'},
        {options: 'Över 5 Timmar', values: '92'},
      ],
    };
  },
  validations: {
    activityRequest: { required },
    organisation: {required},
    firstName: { required },
    lastName: { required },
    homeAddress: { required },
    zipCode: { required },
    region: { required },
    userEmail: { required, email },
    userPhone: { required, checkPhone },
    userBank: { required },
    bankClearing: { required, validateClearing },
    bankAccountNumber: { required },
    ssn: { required, isValidSSN },
    ssnYear: { required, yearLength },
    ssnMM: { required, ddLength },
    ssnDD: { required, ddLength },
    ssnXX: { required, yearLength },
    milesInput: {
      $each: {
        milesFrom: {
          required,
        },
        milesTo: {
          required,
        },
        drovenMiles: {
          required,
          numeric,
        },
        milesDateFrom: {
          required,
          checkDateFormat,
          checkDatePrev,
        },
        milesDateTo: {
          required,
          checkDateFormat,
          checkDatePrev,
        },
      },
    },
    expensesInput: {
      $each: {
        accountableExpense: { required },
        accountableAmount: { required, decimal },
        proofDoc: { required },
        expenseDate: { required, checkDateFormat, checkDatePrev },
        expenseType: { required, validateExpenseType },
      },
    },
    meetingInput: {
      $each: {
        comment: {},
        date: { required, checkDateFormat, checkDatePrev, dateUnique },
        type: { required },
      },
    },
    reimbrushmentInput: {
      $each: {
        description: { required },
        dateFrom: { required, checkDateFormat, checkDatePrev },
        dateTo: { required, checkDateFormat, checkDatePrev },
        totalNights: { required, numeric },
        freeMealsBR: { numeric },
        freeMealsLN: { numeric },
        freeMealsDN: { numeric },
      },
    },
  },

  computed: {
  },
  watch: {
    ssnYear(val) {
      this.ssn = val + this.ssnMM + this.ssnDD + this.ssnXX;
    },
    ssnMM(val) {
      this.ssn = this.ssnYear + val + this.ssnDD + this.ssnXX;
    },
    ssnDD(val) {
      this.ssn = this.ssnYear + this.ssnMM + val + this.ssnXX;
    },
    ssnXX(val) {
      this.ssn = this.ssnYear + this.ssnMM + this.ssnDD + val;
    },
  },
  methods: {
    addNewForm() {
      this.$v.$reset();
      this.hasSent = false
      this.activityRequest = '';
      this.organisation = '';
      this.costLocation = '';
      this.costProject = '';
      this.firstName = '';
      this.lastName = '';
      this.homeAddress = '';
      this.zipCode = '';
      this.region = '';
      this.userEmail = '';
      this.userPhone = '';
      this.userBank = '';
      this.bankClearing = '';
      this.bankAccountNumber = '';
      this.milesInput = [];
      this.expensesInput = [];
      this.reimbrushmentInput = [];
      this.meetingInput = [];
      this.userManager = '';
      this.ssnYear = '';
      this.ssnMM = '';
      this.ssnDD = '';
      this.ssnXX = '';
      this.ssn = '';
      this.activeWindow = '';
      this.hasError = false;
      this.hasSent = false;
      this.sentError = false;

      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.milesInput.forEach((field, index) => {
        this.$v.milesInput[index].$reset();
      });
    },
    async uploadFile(file, expensesIndex) {
      const selectedFile = file;
      if (!selectedFile) {
        return;
      }
      const customForm = new FormData();
      customForm.append('files', selectedFile);
      customForm.append('action', 'upload');
      
      await axios.post('https://api.ersattning.centerpartiet.kcab.se/handler.php?upload=tempFile', customForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(response => {
          if (response.data.status == 'true') {
            this.$v.expensesInput.$each[expensesIndex].proofDoc.$touch();
            this.expensesInput[expensesIndex].proofDoc = response.data.filename;
            this.$set(this.expensesInput[expensesIndex], 'proofDocIsOkay', true)
            this.$set(this.expensesInput[expensesIndex], 'touched', true);
            // console.log(response.data.status);
            this.$set(this.expensesInput[expensesIndex], 'format', true);
            this.$set(this.expensesInput[expensesIndex], 'size', true);
          } else {
            // console.log(response.data.uploadError);
            if (response.data.uploadError === 'format') {
              this.$set(this.expensesInput[expensesIndex], 'touched', true);
              this.$set(this.expensesInput[expensesIndex], 'proofDocIsOkay', false);
              this.$set(this.expensesInput[expensesIndex], 'format', false);
            }

            if (response.data.uploadError === 'size') {
              this.$set(this.expensesInput[expensesIndex], 'size', false);
              this.$set(this.expensesInput[expensesIndex], 'proofDocIsOkay', false);
              this.$set(this.expensesInput[expensesIndex], 'touched', true);
            }
          }
        })
        .catch(error => {
          console.error('An error occurred:', error);
        });
    },

    addMilesInput() {
      this.milesInput.push({ milesFrom: '', milesTo: '', drovenMiles: '', milesDateFrom: '', milesDateTo: '' });
    },

    removeMilesInput(milesIndex) {
      this.$v.milesInput.$reset(milesIndex, 1);
      this.milesInput.splice(milesIndex, 1);
    },

    addExpense() {
      this.expensesInput.push({ accountableExpense: '', accountableAmount: '', expenseDate: '', proofDoc: '' });
    },

    removeExpense(expensesIndex) {
      this.$v.expensesInput.$reset(expensesIndex, 1);
      this.expensesInput.splice(expensesIndex, 1);
    },

    addReimbrushment() {
      this.reimbrushmentInput.push({ description: '', dateFrom: '', dateTo: '', totalNights: '', freeMealsBR: '', freeMealsLN: '', freeMealsDN: '' });
    },
    
    addMeeting() {
      this.meetingInput.push({ date: '', comment: '', type: '', });
    },
    removeMeeting(meetingIndex) {
      this.$v.meetingInput.$reset(meetingIndex, 1);
      this.meetingInput.splice(meetingIndex, 1);
    },

    removeReimbrushment(reimbrushmentIndex) {
      this.$v.reimbrushmentInput.$reset(reimbrushmentIndex, 1);
      this.reimbrushmentInput.splice(reimbrushmentIndex, 1);
    },

    milesValidation(fieldIndex, milesName) {
      return this.$v.milesInput.$each[fieldIndex][milesName];
    },
    yearCheck(val) {
      if (val.length == 4) {
        this.$refs.MM.focus();
      }
    },
    monthCheck(val) {
      if (val.length == 2) {
        this.$refs.DD.focus();
      } else if (val === '') {
        this.$refs.YY.$el.children[3].querySelector('input').focus();
      } else {
        return;
      }

    },
    dateCheck(val) {
      if (val.length == 2) {
        this.$refs.XX.focus();
      } else if (val === '') {
        this.$refs.MM.$el.children[1].querySelector('input').focus();
      } else {
        return;
      }
    },
    
    xxCheck(val) {
      if (val === '') {
        this.$refs.DD.$el.children[1].querySelector('input').focus();
      }
    },
    changeActive(str) {
      this.activeWindow = str;
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.hasError = true;
        return false;
      }
      this.waitingResponse = true;
        this.hasError = false;
        const postData = { ...this.$data }
                axios.post('https://api.ersattning.centerpartiet.kcab.se/handler.php?form=post', postData, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
          // axios.post('handler.php?form=post', postData)
          .then(response => {
            if (response.data == 'Success') {
              this.hasSent = true;
              this.sentError = false;
            } else {
              this.sentError = true;
            }
            this.waitingResponse = false;
          })
          .catch(error => {
            console.error('An error occurred:', error);
            console.error('Axios Error:', error);
            console.error('Status Code:', error.response.status);
            console.error('Response Data:', error.response.data);
          });
      }
    },
};


</script>

<style>.greenNav {
  color: #fff !important;
  background-color: #114838 !important;
  font-weight: bold;
  padding: 6px;
  height: 64px !important;
}

.formBox {
  box-shadow: inset 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 12px;
  margin-bottom: 20px;
  margin-top: 10px;
  border-radius: 8px;
}

.cardHeader {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #114838;
}

.centerCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}</style>
